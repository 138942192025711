/* eslint-disable import/default */

import * as Visualizations from 'common/visualizations';
import { assetIdFor, fetchApprovalsGuidanceV2, withGuidanceV2 } from 'common/core/approvals/index_new';

import ColumnEditor from './ColumnEditor';
import RowEditor from './RowEditor';
import QueryEditor from './QueryEditor';

window.blist = window.blist || {};
window.blist.Visualizations = Visualizations;
window.blist.gridViewColumnEditor = ColumnEditor;
window.blist.gridViewRowEditor = RowEditor;
window.blist.gridViewQueryEditor = QueryEditor;

if (window.blist.dataset) {
  const assetId = assetIdFor(window.blist.dataset.id);
  fetchApprovalsGuidanceV2(assetId).then(guidance => {
    if (withGuidanceV2(guidance).willEnterApprovalQueue()) {
      // EN-21601: If we're on a working copy, conditionally show an approval warning message
      document.getElementById('working-copy-approval-message').style.display = 'inherit';
    }
  });
}
