/**
 * EN-16481 - Alternate column edit mechanism for grid view
 */
// Must use require() instead of import because this module assigns
// module.exports. Consider reworking this file to use the export keyword
// instead.
const I18n = require('common/i18n').default;
const $ = require('jquery');
const { purify } = require('common/purify');
const { TIME_FORMAT_TITLES } = require('common/DataTypeFormatter');

module.exports = function(options) {

  function renderNumberFormattingPreview(format) {
    var precision = _.get(format, 'precision', null);
    var showThousandsSeparators = !(_.get(format, 'noCommas', false));
    var thousandsSeparator = _.get(format, 'groupSeparator', null);
    var decimalSeparator = _.get(format, 'decimalSeparator', null);
    var formattedNumber = '1{THOUSANDS}000{DECIMAL}1234567890';

    if (precision !== null) {
      if (precision === 0) {
        formattedNumber = formattedNumber.substring(0, 15);
      } else {
        formattedNumber = formattedNumber.substring(0, 24 + precision);
      }
    }

    if (!showThousandsSeparators) {
      formattedNumber = formattedNumber.replace('{THOUSANDS}', '');
    } else if (thousandsSeparator === null) {
      formattedNumber = formattedNumber.replace('{THOUSANDS}', ',');
    } else {
      formattedNumber = formattedNumber.replace('{THOUSANDS}', thousandsSeparator);
    }

    if (decimalSeparator === null) {
      formattedNumber = formattedNumber.replace('{DECIMAL}', '.');
    } else {
      formattedNumber = formattedNumber.replace('{DECIMAL}', decimalSeparator);
    }

    return formattedNumber;
  }

  function renderColumnEditor(viewUid, columnsToRender, isSoqlBasedView) {

    function renderNumberFormatting(column) {

      function renderDisplayFormatOptions() {
        var displayFormat = _.get(column, 'format.precisionStyle', 'standard');
        var formats = [
          {value: 'standard', text: I18n.t('core.precision_style.standard')},
          {value: 'scientific', text: I18n.t('core.precision_style.scientific')},
          {value: 'percentage', text: I18n.t('core.precision_style.percentage')},
          {value: 'currency', text: I18n.t('core.precision_style.currency')},
          {value: 'financial', text: I18n.t('core.precision_style.financial')}
        ];

        return formats.map(function(option) {
          var isSelected = (option.value === displayFormat) ? ' selected' : '';

          return (
            '<option ' +
              'value="' + option.value + '"' +
              isSelected + '>' +
                option.text +
            '</option>'
          );
        }).join('');
      }

      function renderCurrencyOptions() {
        var currencyStyle = _.get(column, 'format.currencyStyle', 'USD');

        function renderCurrencies(group) {

          return group.map(function(option) {
            var isSelected = (option.value === currencyStyle) ? ' selected' : '';

            return (
              '<option ' +
                'value="' + option.value + '"' +
                isSelected + '>' +
                  option.text +
              '</option>'
            );
          }).join('');
        }

        var commonCurrencies = [
          {value: 'USD', text: 'United States, Dollars (USD)'},
          {value: 'EUR', text: 'Eurozone, Euros (EUR)'},
          {value: 'GBP', text: 'Great Britain, Pounds (GBP)'},
          {value: 'RUB', text: 'Russia, Rubles (RUB)'},
          {value: 'CAD', text: 'Canada, Dollars (CAD)'}
        ];
        var otherCurrencies = [
          {value: 'AFN', text: 'Afghanistan, Afghanis (AFN)'},
          {value: 'ALL', text: 'Albania, Leke (ALL)'},
          {value: 'ARS', text: 'Argentina, Pesos (ARS)'},
          {value: 'AUD', text: 'Australia, Dollars (AUD)'},
          {value: 'AZN', text: 'Azerbaijan, New Manats (AZN)'},
          {value: 'BSD', text: 'Bahamas, Dollars (BSD)'},
          {value: 'BBD', text: 'Barbados, Dollars (BBD)'},
          {value: 'BYR', text: 'Belarus, Rubles (BYR)'},
          {value: 'BZD', text: 'Belize, Dollars (BZD)'},
          {value: 'BMD', text: 'Bermuda, Dollars (BMD)'},
          {value: 'BOB', text: 'Bolivia, Bolivianos (BOB)'},
          {value: 'BAM', text: 'Bosnia and Herzegovina, Convertible Marka (BAM)'},
          {value: 'BWP', text: 'Botswana, Pulas (BWP)'},
          {value: 'BRL', text: 'Brazil, Real (BRL)'},
          {value: 'BGN', text: 'Bulgaria, Leva (BGN)'},
          {value: 'KHR', text: 'Cambodia, Riels (KHR)'},
          {value: 'CLP', text: 'Chile, Pesos (CLP)'},
          {value: 'CNY', text: 'China, Yuan Renminbi (CNY)'},
          {value: 'COP', text: 'Colombia, Pesos (COP)'},
          {value: 'CRC', text: 'Costa Rica, Colones (CRC)'},
          {value: 'HRK', text: 'Croatia, Kuna (HRK)'},
          {value: 'CZK', text: 'Czech Republic, Koruny (CZK)'},
          {value: 'DKK', text: 'Denmark, Kroner (DKK)'},
          {value: 'DOP', text: 'Dominican Republic, Pesos (DOP)'},
          {value: 'EGP', text: 'Egypt, Pounds (EGP)'},
          {value: 'EEK', text: 'Estonia, Krooni (EEK)'},
          {value: 'FJD', text: 'Fiji, Dollars (FJD)'},
          {value: 'GHC', text: 'Ghana, Cedis (GHC)'},
          {value: 'GTQ', text: 'Guatemala, Quetzales (GTQ)'},
          {value: 'GYD', text: 'Guyana, Dollars (GYD)'},
          {value: 'HKD', text: 'Hong Kong, Dollars (HDK)'},
          {value: 'HNL', text: 'Honduras, Lempiras (HNL)'},
          {value: 'HUF', text: 'Hungary, Forint (HUF)'},
          {value: 'ISK', text: 'Iceland, Kronur (ISK)'},
          {value: 'INR', text: 'India, Rupees (INR)'},
          {value: 'IDR', text: 'Indonesia, Rupiahs (IDR)'},
          {value: 'IRR', text: 'Iran, Rials (IRR)'},
          {value: 'ILS', text: 'Israel, New Shekels (ILS)'},
          {value: 'JMD', text: 'Jamaica, Dollars (JMD)'},
          {value: 'JPY', text: 'Japanese Yen (JPY)'},
          {value: 'KZT', text: 'Kazakhstan, Tenge (KZT)'},
          {value: 'KES', text: 'Kenya, Shilling (KES)'},
          {value: 'KRW', text: 'Korea, Won (KRW)'},
          {value: 'KGS', text: 'Kyrgyzstan, Soms (KGS)'},
          {value: 'LAK', text: 'Laos, Kips (LAK)'},
          {value: 'LVL', text: 'Latvia, Lati (LVL)'},
          {value: 'LBP', text: 'Lebanon, Pounds (LBP)'},
          {value: 'LRD', text: 'Liberia, Dollars (LRD)'},
          {value: 'LTL', text: 'Lithuania, Litai (LTL)'},
          {value: 'MKD', text: 'Macedonia, Denars (MKD)'},
          {value: 'MYR', text: 'Malaysia, Ringgits (MYR)'},
          {value: 'MXN', text: 'Mexico, Pesos (MXN)'},
          {value: 'MNT', text: 'Mongolia, Tugriks (MNT)'},
          {value: 'MZN', text: 'Mozambique, Meticais (MZN)'},
          {value: 'NAD', text: 'Namibia, Dollars (NAD)'},
          {value: 'NPR', text: 'Nepal, Nepal Rupees (NPR)'},
          {value: 'NZD', text: 'New Zealand, Dollar (NZD)'},
          {value: 'NIO', text: 'Nicaragua, Cordobas (NIO)'},
          {value: 'NGN', text: 'Nigeria, Nairas (NGN)'},
          {value: 'NOK', text: 'Norway, Krone (NOK)'},
          {value: 'OMR', text: 'Oman, Rials (OMR)'},
          {value: 'PKR', text: 'Pakistan, Rupees (PKR)'},
          {value: 'PYG', text: 'Paraguay, Guarani (PYG)'},
          {value: 'PEN', text: 'Peru, Nuevos Soles (PEN)'},
          {value: 'PHP', text: 'Philippines, Pesos (PHP)'},
          {value: 'PLN', text: 'Poland, Klotych (PLN)'},
          {value: 'QAR', text: 'Qatar, Rials (QAR)'},
          {value: 'RON', text: 'Romania, New Lei (RON)'},
          {value: 'SAR', text: 'Saudi Arabia, Riyals (SAR)'},
          {value: 'RSD', text: 'Serbia, Dinars (RSD)'},
          {value: 'SGD', text: 'Singapore, Dollars (SGD)'},
          {value: 'SOS', text: 'Somalia, Shillings (SOS)'},
          {value: 'ZAR', text: 'South Africa, Rand (ZAR)'},
          {value: 'LKR', text: 'Sri Lanka, Rupees (LKR)'},
          {value: 'SEK', text: 'Sweden, Kronor (SEK)'},
          {value: 'CHF', text: 'Swiss, Francs (CHF)'},
          {value: 'SYP', text: 'Syria, Pounds (SYP)'},
          {value: 'TWD', text: 'Taiwan, New Dollars (TWD)'},
          {value: 'THB', text: 'Thailand, Baht (THB)'},
          {value: 'TRY', text: 'Turkey, New Lira (TRY)'},
          {value: 'UAH', text: 'Ukraine, Hryvnia (UAH)'},
          {value: 'UYU', text: 'Uruguay, Pesos (UYU)'},
          {value: 'UZS', text: 'Uzbekistan, Sums (UZS)'},
          {value: 'VEF', text: 'Venezuela, Bolivares Fuertes (VEF)'},
          {value: 'VND', text: 'Vietnam, Dong (VND)'},
          {value: 'YER', text: 'Yemen, Rials (YER)'}
        ];

        return (
          '<optgroup ' +
            'label="' + I18n.t('controls.grid_view_column_editor.column_fields.number_formatting.currency.common') + '">' +
            renderCurrencies(commonCurrencies) +
          '</optgroup>' +
          '<optgroup ' +
            'label="' + I18n.t('controls.grid_view_column_editor.column_fields.number_formatting.currency.other_currency') + '">' +
            renderCurrencies(otherCurrencies) +
          '</optgroup>'
        );
      }

      var id = column.id;
      var format = _.get(column, 'format', {});
      // Currency
      var currencyHidden = (_.get(format, 'precisionStyle', 'standard') !== 'currency') ? ' hidden' : '';
      // Precision
      var precisionValue = _.get(format, 'precision', 5);
      var overridePrecision = _.get(format, 'precision', null);
      var overridePrecisionChecked = (overridePrecision === null) ? '' : ' checked ';
      var overridePrecisionControlHidden = (overridePrecision === null) ? ' hidden' : '';
      // Show thousands separators
      // Note that we are inverting the truthiness of the 'noCommas' property in
      // the view in order to make it easier to understand as "show this thing"
      // as opposed to "don't show this thing", and furthermore, that the
      // thousands-separator might not actually be a comma (ugh).
      var showThousandsSeparators = !_.get(format, 'noCommas', false);
      var showThousandsSeparatorsChecked = (showThousandsSeparators) ? ' checked' : '';
      // Override thousands separator
      var overrideThousandsSeparator = _.get(format, 'groupSeparator', null);
      var overrideThousandsSeparatorChecked = (overrideThousandsSeparator === null) ? '' : ' checked ';
      var overrideThousandsSeparatorControlHidden = (overrideThousandsSeparator === null) ? ' hidden' : '';
      var thousandsSeparatorValue = (overrideThousandsSeparator === null) ?
        ',' :
        _.get(format, 'groupSeparator', '');
      // Override decimal separator
      var overrideDecimalSeparator = _.get(format, 'decimalSeparator', null);
      var overrideDecimalSeparatorChecked = (overrideDecimalSeparator === null) ? '' : ' checked ';
      var overrideDecimalSeparatorControlHidden = (overrideDecimalSeparator === null) ? ' hidden' : '';
      var decimalSeparatorValue = (overrideDecimalSeparator === null) ?
        '.' :
        _.get(format, 'decimalSeparator', '');
      var percentScale = _.get(format, 'percentScale', 1);
      return (
        '<div class="field-group number-formatting">' +
          // Display format
          '<div class="field">' +
            '<label ' +
              'class="top" ' +
              'for="column-number-formatting-display-format-' + id + '" ' +
              'title="' + I18n.t('controls.grid_view_column_editor.column_fields.number_formatting.display_format.title') + '">' +
              I18n.t('controls.grid_view_column_editor.column_fields.number_formatting.display_format.title') +
            '</label>' +
            '<select ' +
              'id="column-number-formatting-display-format-' + id + '" ' +
              'class="column-number-formatting-display-format" ' +
              'name="column-number-formatting-display-format-' + id + '">' +
              renderDisplayFormatOptions() +
            '</select>' +
          '</div>' +
          // Currency
          '<div class="field optional' + currencyHidden + '">' +
            '<label ' +
              'class="top" ' +
              'for="column-number-formatting-currency-' + id + '" ' +
              'title="' + I18n.t('controls.grid_view_column_editor.column_fields.number_formatting.currency.title') + '">' +
              I18n.t('controls.grid_view_column_editor.column_fields.number_formatting.currency.title') +
            '</label>' +
            '<select ' +
              'id="column-number-formatting-currency-' + id + '" ' +
              'class="column-number-formatting-currency" ' +
              'name="column-number-formatting-currency-' + id + '">' +
              renderCurrencyOptions() +
            '</select>' +
          '</div>' +
          '<h4>' + I18n.t('controls.grid_view_column_editor.column_fields.number_formatting.advanced.title') + '</h4>' +
          '<div class="field">' +
            '<input ' +
              'id="column-number-formatting-override-precision-' + id + '" ' +
              'class="column-number-formatting-override-precision" ' +
              'type="checkbox" ' +
              'name="column-number-formatting-override-precision-' + id + '" ' +
              'value="override" ' +
              overridePrecisionChecked + '/>' +
            '<label ' +
              'class="right" ' +
              'for="column-number-formatting-override-precision-' + id + '" ' +
              'title="' + I18n.t('controls.grid_view_column_editor.column_fields.number_formatting.advanced.precision') + '">' +
              I18n.t('controls.grid_view_column_editor.column_fields.number_formatting.advanced.precision') +
            '</label>' +
            '<div ' +
              'id="column-number-formatting-override-precision-' + id + '-control" ' +
              'class="column-number-formatting-override-precision-control' + overridePrecisionControlHidden + '">' +
              '<input ' +
                'id="column-number-formatting-override-precision-' + id + '-range" ' +
                'class="column-number-formatting-override-precision-range" ' +
                'type="range" ' +
                'min="0" ' +
                'max="10" ' +
                'step="1" ' +
                'list="column-number-formatting-override-precision-' + id + '-range-tickmarks" ' +
                'value="' + precisionValue + '" />' +
              '<datalist id="column-number-formatting-override-precision-' + id + '-range-tickmarks">' +
                '<option value="0" label="0">' +
                '<option value="1">' +
                '<option value="2">' +
                '<option value="3">' +
                '<option value="4">' +
                '<option value="5" label="5">' +
                '<option value="6">' +
                '<option value="7">' +
                '<option value="8">' +
                '<option value="9">' +
                '<option value="10" label="10">' +
              '</datalist>' +
            '</div>' +
          '</div>' +
          // Show thousands separators
          '<div class="field">' +
            '<input ' +
              'id="column-number-formatting-show-thousands-separators-' + id + '" ' +
              'class="column-number-formatting-show-thousands-separators" ' +
              'type="checkbox" ' +
              'name="column-number-formatting-show-thousands-separators-' + id + '"' + showThousandsSeparatorsChecked + '>' +
            '<label ' +
              'class="right" ' +
              'for="column-number-formatting-show-thousands-separators-' + id + '" ' +
              'title="' + I18n.t('controls.grid_view_column_editor.column_fields.number_formatting.advanced.show_thousands_separators') + '">' +
              I18n.t('controls.grid_view_column_editor.column_fields.number_formatting.advanced.show_thousands_separators') +
            '</label>' +
          '</div>' +
          // Override thousands separator
          '<div class="field">' +
            '<input ' +
              'id="column-number-formatting-override-thousands-separator-' + id + '" ' +
              'class="column-number-formatting-override-thousands-separator" ' +
              'type="checkbox" ' +
              'name="column-number-formatting-override-thousands-separator-' + id + '"' + overrideThousandsSeparatorChecked + '>' +
            '<label ' +
              'class="right" ' +
              'for="column-number-formatting-override-thousands-separator-' + id + '" ' +
              'title="' + I18n.t('controls.grid_view_column_editor.column_fields.number_formatting.advanced.override_thousands_separator.title') + '">' +
              I18n.t('controls.grid_view_column_editor.column_fields.number_formatting.advanced.override_thousands_separator.title') +
            '</label>' +
            '<div ' +
              'id="column-number-formatting-override-thousands-separator-' + id + '-control" ' +
              'class="column-number-formatting-override-thousands-separator-control' + overrideThousandsSeparatorControlHidden + '">' +
              '<input ' +
                'id="column-number-formatting-override-thousands-separator-' + id + '-value" ' +
                'class="column-number-formatting-override-thousands-separator-value" ' +
                'type="text" ' +
                'name="column-number-formatting-override-thousands-separator-' + id + '-value" ' +
                'title="' + I18n.t('controls.grid_view_column_editor.column_fields.number_formatting.advanced.override_thousands_separator.title') + '" ' +
                'value="' + thousandsSeparatorValue + '" ' +
                'placeholder="' + I18n.t('controls.grid_view_column_editor.column_fields.number_formatting.advanced.override_thousands_separator.placeholder') + '">' +
            '</div>' +
          '</div>' +
          // Override decimal separator
          '<div class="field">' +
            '<input ' +
              'id="column-number-formatting-override-decimal-separator-' + id + '" ' +
              'class="column-number-formatting-override-decimal-separator" ' +
              'type="checkbox" ' +
              'name="column-number-formatting-override-decimal-separator-' + id + '"' + overrideDecimalSeparatorChecked + '>' +
            '<label ' +
              'class="right" ' +
              'for="column-number-formatting-override-decimal-separator-' + id + '" ' +
              'title="' + I18n.t('controls.grid_view_column_editor.column_fields.number_formatting.advanced.override_decimal_separator.title') + '">' +
              I18n.t('controls.grid_view_column_editor.column_fields.number_formatting.advanced.override_decimal_separator.title') +
            '</label>' +
            '<div ' +
              'id="column-number-formatting-override-decimal-separator-' + id + '-control" ' +
              'class="column-number-formatting-override-decimal-separator-control' + overrideDecimalSeparatorControlHidden + '">' +
              '<input ' +
                'id="column-number-formatting-override-decimal-separator-' + id + '-value" ' +
                'class="column-number-formatting-override-decimal-separator-value" ' +
                'type="text" ' +
                'name="column-number-formatting-override-decimal-separator-' + id + '-value" ' +
                'title="' + I18n.t('controls.grid_view_column_editor.column_fields.number_formatting.advanced.override_decimal_separator.title') + '" ' +
                'value="' + decimalSeparatorValue + '" ' +
                'placeholder="' + I18n.t('controls.grid_view_column_editor.column_fields.number_formatting.advanced.override_decimal_separator.placeholder') + '">' +
            '</div>' +
            '<input id="column-number-formatting-percent-scale-' + id + '" type="hidden" value="' + percentScale + '">' +
          '</div>' +
        '</div>' +
        // Preview
        '<div class="field-group preview">' +
          '<div class="field">' +
            '<label ' +
              'class="top" ' +
              'for="column-number-formatting-' + id + '-preview" ' +
              'title="' + I18n.t('controls.grid_view_column_editor.column_fields.preview.title') + '">' +
              I18n.t('controls.grid_view_column_editor.column_fields.preview.title') +
            '</label>' +
            '<input ' +
              'id="column-number-formatting-' + id + '-preview" ' +
              'class="column-number-formatting-preview" ' +
              'type="text" ' +
              'value="' + renderNumberFormattingPreview(column.format) + '" ' +
              'disabled>' +
            '<span class="column-number-formatting-preview-note">' +
              I18n.t('controls.grid_view_column_editor.column_fields.preview.note') +
            '</span>' +
          '</div>' +
        '</div>'
      );
    }

    function renderDateFormatting(column) {

      function renderDateFormatOptions() {
        var displayFormat = _.get(column, 'format.view', null);
        var formats = TIME_FORMAT_TITLES.map(({ value, title }) => {
          return {
            value,
            text: title
          };
        });

        return formats.map(function(option) {
          var isSelected = (option.value === displayFormat) ? ' selected' : '';

          return '<option value="' + option.value + '"' + isSelected + '>' + option.text + '</option>';
        }).join('');
      }

      var id = column.id;

      return (
        '<div class="field-group date-formatting">' +
          '<div class="field">' +
            '<label ' +
              'class="top" ' +
              'for="column-date-formatting-display-format-' + id + '" ' +
              'title="' + I18n.t('controls.grid_view_column_editor.column_fields.date_formatting.display_format.title') + '">' +
              I18n.t('controls.grid_view_column_editor.column_fields.date_formatting.display_format.title') +
            '</label>' +
            '<select ' +
              'id="column-date-formatting-display-format-' + id + '" ' +
              'class="column-date-formatting-display-format" ' +
              'name="column-date-formatting-display-format-' + id + '">' +
              renderDateFormatOptions() +
            '</select>' +
          '</div>' +
        '</div>'
      );
    }

    function renderColumnProperties(column) {

      function renderHideColumnSection(columnIsPartOfSoqlBasedView, columnId, columnIsHidden) {

        if (columnIsPartOfSoqlBasedView) {

          return (
            '<div class="field column-hidden">' +
              // Column is hidden in the view
              '<input ' +
                'id="column-hidden-' + columnId + '" ' +
                'class="column-hidden disabled" ' +
                'type="checkbox" ' +
                'name="column-hidden-' + columnId + '"' +
                'disabled="disabled">' +
              '<label ' +
                'class="right" ' +
                'for="column-hidden-' + columnId + '" ' +
                'title="' + I18n.t('controls.grid_view_column_editor.column_fields.hidden_disabled_because_view_has_soql_query.title') + '">' +
                I18n.t('controls.grid_view_column_editor.column_fields.hidden_disabled_because_view_has_soql_query.title') +
              '</label>' +
              '<p class="column-hiding-disabled-note">' +
                I18n.t('controls.grid_view_column_editor.column_fields.hidden_disabled_because_view_has_soql_query.note') +
              '</p>' +
            '</div>'
          );
        } else {

          var hiddenValue = (columnIsHidden) ? ' checked' : '';

          return (
            '<div class="field column-hidden">' +
              // Column is hidden in the view
              '<input ' +
                'id="column-hidden-' + columnId + '" ' +
                'class="column-hidden" ' +
                'type="checkbox" ' +
                'name="column-hidden-' + columnId + '"' +
                hiddenValue + '>' +
              '<label ' +
                'class="right" ' +
                'for="column-hidden-' + columnId + '" ' +
                'title="' + I18n.t('controls.grid_view_column_editor.column_fields.hidden.title') + '">' +
                I18n.t('controls.grid_view_column_editor.column_fields.hidden.title') +
              '</label>' +
            '</div>'
          );
        }
      }

      function renderFieldNameSection(columnIsPartOfSoqlBasedView, columnId, columnFieldName) {

        if (columnIsPartOfSoqlBasedView) {

          return (
            '<div class="field column-field-name">' +
              '<label ' +
                'class="top" ' +
                'for="column-field-name-' + columnId + '" ' +
                'title="' + I18n.t('controls.grid_view_column_editor.field_name_read_only_because_view_has_soql_query.title') + '">' +
                I18n.t('controls.grid_view_column_editor.field_name_read_only_because_view_has_soql_query.title') +
              '</label>' +
              '<input ' +
                'id="column-field-name-' + columnId + '" ' +
                'class="column-field-name" ' +
                'type="text" ' +
                'name="column-field-name-' + columnId + '" ' +
                'value="' + columnFieldName + '" ' +
                'disabled="disabled" />' +
              '<p class="column-field-name-read-only-note">' +
                I18n.t('controls.grid_view_column_editor.field_name_read_only_because_view_has_soql_query.note') +
              '</p>' +
            '</div>'
          );
        } else {

          return (
            '<div class="field column-field-name">' +
              '<label ' +
                'class="top" ' +
                'for="column-field-name-' + columnId + '" ' +
                'title="' + I18n.t('controls.grid_view_column_editor.field_name.title') + '">' +
                I18n.t('controls.grid_view_column_editor.field_name.title') +
              '</label>' +
              '<input ' +
                'id="column-field-name-' + columnId + '" ' +
                'class="column-field-name" ' +
                'type="text" ' +
                'name="column-field-name-' + columnId + '" ' +
                'value="' + columnFieldName + '" />' +
            '</div>'
          );
        }
      }

      function renderConvertDataTypeOptions() {
        var dataTypeConversionMappings = {
          blob: [],
          calendar_date: ['text'],
          checkbox: ['text'],
          dataset_link: [],
          date: ['calendar_date', 'text'],
          document: [],
          drop_down_list: [],
          email: ['text'],
          flag: ['text'],
          geospatial: [],
          html: ['text'],
          line: [],
          list: [],
          location: [],
          money: ['number'],
          multiline: [],
          multipoint: [],
          multipolygon: [],
          number: ['text'],
          object: [],
          percent: ['text'],
          phone: ['text'],
          photo: [],
          point: [],
          polygon: [],
          stars: ['number'],
          text: ['calendar_date', 'checkbox', 'number'],
          undefined: [],
          url: []
        };

        // NBE doesn't support datetime-with-timezone, but we need to support the OBE case, so
        // conditionally make that conversion option available according to the NBE-ness of the
        // dataset.
        if (!window.blist.dataset.newBackend) {
          dataTypeConversionMappings.calendar_date = ['date', 'text'];
        }

        var dataType = _.get(column, 'dataTypeName');
        var conversionOptions = _.get(dataTypeConversionMappings, dataType, []).
          map(function(conversionOptionDataType) {
            var conversionOptionDataTypeName = I18n.t('controls.grid_view_row_editor.data_types.' + conversionOptionDataType + '.name');

            return '<option value="' + conversionOptionDataType + '">' + conversionOptionDataTypeName + '</option>';
          }).join('');

        return conversionOptions;
      }

      function renderColumnDataTypeSection(columnId) {
        var columnDataTypeSection = '';
        var dataType = _.get(column, 'dataTypeName');
        var conversionOptions = renderConvertDataTypeOptions();

        // The NBE doesn't currently have the capability to convert column data types.
        if (window.blist.dataset.newBackend || !window.blist.dataset.isDefault() || (conversionOptions === '')) {

          columnDataTypeSection = (
            '<input ' +
              'id="column-data-type-' + columnId + '" ' +
              'class="column-data-type" ' +
              'type="text" ' +
              'name="column-data-type-' + columnId + '" ' +
              'value="' + dataType + '" ' +
              'disabled="disabled" />'
          );
        } else {

          columnDataTypeSection = (
            '<input ' +
              'id="column-data-type-' + columnId + '" ' +
              'class="column-data-type" ' +
              'type="text" ' +
              'name="column-data-type-' + columnId + '" ' +
              'value="' + dataType + '" ' +
              'disabled="disabled" />' +
            '<br />' +
            '<label ' +
              'class="top" ' +
              'for="column-data-type-' + id + '" ' +
              'title="' + I18n.t('controls.grid_view_column_editor.change_data_type.title') + '">' +
              I18n.t('controls.grid_view_column_editor.change_data_type.title') +
            '</label>' +
            '<br />' +
            '<select ' +
              'id="column-data-type-select-' + columnId + '" ' +
              'class="column-data-type" ' +
              'type="text" ' +
              'name="column-data-type-select-' + columnId + '">' +
              conversionOptions +
            '</select>' +
            '<button class="convert-column-data-type" data-column-id="' + columnId + '">' +
              I18n.t('controls.grid_view_column_editor.convert_column_data_type') +
            '</button>' +
            '<div id="convert-column-data-type-status-' + columnId + '" class="convert-column-data-type-status">' +
              '<span class="spinner"></span>' +
              '<span class="convert-column-data-type-status-text"></span>' +
            '</div>'
          );
        }

        return columnDataTypeSection;
      }

      var id = column.id;
      var fieldName = column.fieldName;
      var hidden = ((_.get(column, 'flags') || []).indexOf('hidden') >= 0) ? true : false;
      var nameValue = _.get(column, 'name', '');
      var descriptionValue = _.get(column, 'description', '');

      return (
        '<div class="tab-content" data-tab-content data-tab-id="column-properties">' +
          '<div class="field-group">' +
            renderHideColumnSection(isSoqlBasedView, id, hidden) +
            '<div class="field">' +
              // Column name
              '<label ' +
                'class="top" ' +
                'for="column-name-' + id + '" ' +
                'title="' + I18n.t('controls.grid_view_column_editor.column_fields.name.title') + '" ' +
                'aria-required="true">' +
                I18n.t('controls.grid_view_column_editor.column_fields.name.title') +
              '</label>' +
              '<input ' +
                'id="column-name-' + id + '" ' +
                'class="column-name" ' +
                'type="text" ' +
                'name="column-name-' + id + '" ' +
                'title="' + I18n.t('controls.grid_view_column_editor.column_fields.name.title') + '" ' +
                'value="' + nameValue + '" ' +
                'placeholder="' + I18n.t('controls.grid_view_column_editor.column_fields.name.placeholder') + '" ' +
                'aria-required="true" ' +
                'aria-invalid="false">' +
            '</div>' +
            // Column description
            '<div class="field">' +
              '<label ' +
                'class="top" ' +
                'for="column-description-' + id + '" ' +
                'title="' + I18n.t('controls.grid_view_column_editor.column_fields.description.title') + '">' +
                I18n.t('controls.grid_view_column_editor.column_fields.description.title') +
              '</label>' +
              '<textarea ' +
                'id="column-description-' + id + '" ' +
                'class="column-description" ' +
                'name="column-description-' + id + '" ' +
                'title="' + I18n.t('controls.grid_view_column_editor.column_fields.description.title') + '" ' +
                'placeholder="' + I18n.t('controls.grid_view_column_editor.column_fields.description.placeholder') + '">' +
                descriptionValue +
              '</textarea>' +
            '</div>' +
          '</div>' +
          '<div class="field-group">' +
            renderFieldNameSection(isSoqlBasedView, id, fieldName) +
            '<div class="field column-data-type">' +
              '<label ' +
                'class="top" ' +
                'for="column-data-type-' + id + '" ' +
                'title="' + I18n.t('controls.grid_view_column_editor.data_type.title') + '">' +
                I18n.t('controls.grid_view_column_editor.data_type.title') +
              '</label>' +
              '<div id="column-data-type-container-' + id + '" class="convert-column-type-container">' +
                renderColumnDataTypeSection(id) +
              '</div>' +
            '</div>' +
          '</div>' +
        '</div>'
      );
    }

    function renderColumnFormatting(column) {

      function renderAlignmentOptions() {
        var alignment = _.get(column, 'format.align', 'left');
        var alignments = [
          {value: 'left', text: I18n.t('controls.grid_view_column_editor.column_fields.alignment.left')},
          {value: 'center', text: I18n.t('controls.grid_view_column_editor.column_fields.alignment.center')},
          {value: 'right', text: I18n.t('controls.grid_view_column_editor.column_fields.alignment.right')}
        ];

        return alignments.map(function(option) {
          var isSelected = (option.value === alignment) ? ' selected' : '';

          return '<option value="' + option.value + '"' + isSelected + '>' + option.text + '</option>';
        }).join('');
      }

      var id = column.id;
      var formattingOptions =
        '<div class="field-group">' +
          // Column alignment
          '<div class="field">' +
            '<label ' +
              'class="top" ' +
              'for="column-alignment-' + id + '" ' +
              'title="' + I18n.t('controls.grid_view_column_editor.column_fields.alignment.title') + '">' +
              I18n.t('controls.grid_view_column_editor.column_fields.alignment.title') +
            '</label>' +
            '<select ' +
              'id="column-alignment-' + id + '" ' +
              'class="column-alignment" ' +
              'name="column-alignment-' + id + '">' +
              renderAlignmentOptions() +
            '</select>' +
          '</div>' +
        '</div>';

      switch (column.dataTypeName.toLowerCase()) {
        case 'number':
          formattingOptions += renderNumberFormatting(column);
          break;
        case 'calendar_date':
          formattingOptions += renderDateFormatting(column);
          break;
        default:
          break;
      }

      return (
        '<div ' +
          'class="tab-content hidden" ' +
          'data-tab-content ' +
          'data-tab-id="column-formatting">' +
          formattingOptions +
        '</div>'
      );
    }

    function renderColumnDelete(column) {

      if (window.blist.dataset.isDefault()) {

        return (
          '<div ' +
            'class="tab-content hidden" ' +
            'data-tab-content ' +
            'data-tab-id="column-delete">' +
            '<button class="delete" data-column-id="' + column.id + '">' +
              I18n.t('controls.grid_view_column_editor.delete_column') +
            '</button>' +
            '<h3 class="delete-column-warning-head">' +
              I18n.t('controls.grid_view_column_editor.delete_column_warning_head') +
            '</h3>' +
            '<p class="delete-column-warning-body">' +
              I18n.t('controls.grid_view_column_editor.delete_column_warning_body') +
            '</p>' +
          '</div>'
        );
      } else {

        return (
          '<div ' +
            'class="tab-content hidden" ' +
            'data-tab-content ' +
            'data-tab-id="column-delete">' +
            '<h3 class="delete-column-warning-head">' +
              I18n.t('controls.grid_view_column_editor.cannot_delete_column_on_derived_view_head') +
            '</h3>' +
            '<p class="delete-column-warning-body">' +
              I18n.t('controls.grid_view_column_editor.cannot_delete_column_on_derived_view_body') +
            '</p>' +
          '</div>'
        );
      }
    }

    function renderColumn(column) {
      var id = column.id;

      return $(
        '<div class="column hidden" data-column-id="' + id + '" data-tabs>' +
          '<ul class="nav-tabs">' +
            '<li class="tab-link current" data-tab-id="column-properties">' +
              I18n.t('controls.grid_view_column_editor.column_properties') +
            '</li>' +
            '<li class="tab-link" data-tab-id="column-formatting">' +
              I18n.t('controls.grid_view_column_editor.column_formatting') +
            '</li>' +
            '<li class="tab-link" data-tab-id="column-delete">' +
              I18n.t('controls.grid_view_column_editor.delete_column') +
            '</li>' +
          '</ul>' +
          renderColumnProperties(column) +
          renderColumnFormatting(column) +
          renderColumnDelete(column) +
        '</div>'
      );
    }

    var columnEditorHtml =
      '<div id="grid-view-column-editor">' +
        '<div class="overlay"></div>' +
        '<div class="modal">' +
          '<div class="header">' +
            '<span class="socrata-icon-settings"></span>' +
            '<h2 id="column-name"></h2>' +
            '<button class="cancel">' +
              '<span class="socrata-icon-close-2"></span>' +
            '</button>' +
          '</div>' +
          '<div class="columns-container">' +
            '<div class="columns"></div>' +
          '</div>' +
          '<div class="controls">' +
            '<button class="prev">' +
              '<span class="socrata-icon-arrow-left"></span>' +
              I18n.t('controls.grid_view_column_editor.controls.prev') +
            '</button>' +
            '<button class="next">' +
              I18n.t('controls.grid_view_column_editor.controls.next') +
              '<span class="socrata-icon-arrow-right"></span>' +
            '</button>' +
            '<button class="cancel">' +
              I18n.t('controls.grid_view_column_editor.controls.cancel') +
            '</button>' +
            '<button class="save">' +
              I18n.t('controls.grid_view_column_editor.controls.save') +
            '</button>' +
          '</div>' +
          '<div class="loadingSpinnerContainer hidden">' +
            '<div class="loadingSpinner"></div>' +
          '</div>' +
        '</div>' +
      '</div>';

    // Render modal skeleton
    var $columnEditorModal = $(columnEditorHtml);

    // Render column headers and columns
    //
    // Whatever version of jQuery is running on this page doesn't support the
    // $().append([$(), $()]) syntax, so do a forEach and call $().append() on
    // each column form instead.
    var $columnEditorColumnsContainer = $columnEditorModal.
      find('.columns-container');
    var $columnEditorColumnsContainerColumns = $columnEditorColumnsContainer.
      find('.columns');

    columnsToRender.forEach(function(column) {
      $columnEditorColumnsContainerColumns.append(renderColumn(column));
    });

    return $columnEditorModal;
  }

  function generateColumnPositionString(columnName, columnIndex, columnCount) {

    return (
      columnName +
      ' (' +
      (columnIndex + 1) +
      ' ' +
      I18n.t('controls.grid_view_column_editor.of') +
      ' ' +
      columnCount +
      ')'
    );
  }

  function attachColumnEditorEvents($manager) {

    function getColumnIdFromEvent(e) {

      return Number(
        $(e.target).parents('.column')[0].getAttribute('data-column-id')
      );
    }

    function getColumnDefaultOptions(id, position) {
      var fieldName = purify($('#column-field-name-' + id).value());
      var name = purify($('#column-name-' + id).value());
      var description = purify($('#column-description-' + id).value());

      var hidden = $('#column-hidden-' + id).is(':checked');

      var column = {
        id: id,
        fieldName: fieldName,
        name: name,
        description: description,
        position: position
      };

      // The Column Editor uses the Views API, flags can only be added
      // via the Views API, therefore it should be safe to only post
      // the flag we wish to add (i.e. omitting other flags will not
      // remove them from the view).
      if (hidden) {
        column.flags = ['hidden'];
      }

      return column;
    }

    function getColumnFormatOptions(id) {
      var alignment = $('#column-alignment-' + id).value();
      var numberFormatting = ($('.column[data-column-id="' + id + '"]').
        find('.field-group.number-formatting').length > 0);
      var dateFormatting = ($('.column[data-column-id="' + id + '"]').
        find('.field-group.date-formatting').length > 0);
      var format = {
        align: alignment
      };

      if (numberFormatting) {
        var displayFormat = $('#column-number-formatting-display-format-' + id).value();
        var currencyStyle = $('#column-number-formatting-currency-' + id).value();
        var overridePrecision = $('#column-number-formatting-override-precision-' + id).is(':checked');
        var overridePrecisionValue = parseInt($('#column-number-formatting-override-precision-' + id + '-range').value(), 10);
        var showThousandsSeparators = $('#column-number-formatting-show-thousands-separators-' + id).is(':checked');
        var overrideThousandsSeparator = $('#column-number-formatting-override-thousands-separator-' + id).is(':checked');
        var overrideThousandsSeparatorValue = $('#column-number-formatting-override-thousands-separator-' + id + '-value').value();
        var overrideDecimalSeparator = $('#column-number-formatting-override-decimal-separator-' + id).is(':checked');
        var overrideDecimalSeparatorValue = $('#column-number-formatting-override-decimal-separator-' + id + '-value').value();

        format.precisionStyle = displayFormat;

        if (displayFormat === 'percentage') {
          var percentScaleField = $('#column-number-formatting-percent-scale-' + id);
          var percentScale = percentScaleField && percentScaleField.length
            ? percentScaleField.value()
            : 1;
          format.percentScale = parseInt(percentScale, 10);
        }

        if (displayFormat === 'currency') {
          format.currencyStyle = currencyStyle;
        }

        if (overridePrecision) {
          format.precision = overridePrecisionValue;
        }

        if (!showThousandsSeparators) {
          format.noCommas = true;
        }

        if (overrideThousandsSeparator) {
          format.groupSeparator = overrideThousandsSeparatorValue;
        }

        if (overrideDecimalSeparator) {
          format.decimalSeparator = overrideDecimalSeparatorValue;
        }
      }

      if (dateFormatting) {
        var dateFormat = $('#column-date-formatting-display-format-' + id).value();

        if (!_.isNull(dateFormat) && dateFormat !== 'null') {
          format.view = dateFormat;
        }
      }

      return format;
    }

    function updateNumberFormattingPreview(id) {

      $('#column-number-formatting-' + id + '-preview').value(
        renderNumberFormattingPreview(getColumnFormatOptions(id))
      );
    }

    function updatePagination(argument, $columnsElements) {
      var columnElementsAsArray = $columnsElements.toArray();
      var newIndex = 0;
      var $newColumn;
      var columnName;

      columnElementsAsArray.forEach(function(column, i) {
        var $column = $(column);

        if (!$column.hasClass('hidden')) {
          newIndex = (i + argument) % columnsToRenderInColumnEditor.length;

          if (newIndex < 0) {
            newIndex += columnsToRenderInColumnEditor.length;
          }

          $column.addClass('hidden');
        }
      });

      $newColumn = $columns.eq(newIndex);
      columnName = $newColumn.find('input.column-name').value();
      $manager.find('#column-name').text(
        generateColumnPositionString(columnName, newIndex, $columnsElements.length)
      );
      $newColumn.removeClass('hidden');
    }

    function saveView(onComplete) {
      var viewToPut = window.blist.dataset.cleanCopy();

      // Update the column metadata with the values in the forms.
      var updatedColumns = $columns.toArray().
        map(function(columnForm, i) {
          var id = parseInt(columnForm.getAttribute('data-column-id'), 10);
          var column = getColumnDefaultOptions(id, i + 1);

          column.format = getColumnFormatOptions(id);

          var columnFromView = _.find(_.get(viewToPut, 'columns', []), { id: id });
          var drillDown = _.get(columnFromView, 'format.drill_down', null);
          var groupFunction = _.get(columnFromView, 'format.group_function', null);
          var groupingAggregate = _.get(columnFromView, 'format.grouping_aggregate', null);

          if (!_.isNull(drillDown)) {
            column.format.drill_down = drillDown;
          }

          if (!_.isNull(groupFunction)) {
            column.format.group_function = groupFunction;
          }

          if (!_.isNull(groupingAggregate)) {
            column.format.grouping_aggregate = groupingAggregate;
          }

          if (_.isNull(column.description)) {
            column.description = '';
          }

          return column;
        }).
        filter(function(column) {
          return !_.includes(_.get(column, 'flags', []), 'hidden');
        });

      viewToPut.columns = updatedColumns;

      var ajaxOptions = {
        url: '/api/views/' + window.blist.dataset.id + '.json',
        type: 'PUT',
        contentType: 'application/json',
        data: JSON.stringify(viewToPut),
        dataType: 'json',
        success: function(updatedView) {

          unhideUnhiddenColumns(
            viewToPut,
            updatedView,
            onComplete
          );
        },
        error: function(jqXHR, textStatus) {
          var validationError = null;
          if (textStatus === 'error' && jqXHR.status === 400) {
            var message = JSON.parse(jqXHR.responseText).message;
            var matches = message.match(/(?:Validation failed: Column )(.+)(?: with id \d+ cannot be hidden if column on derived view )(\w{4}-\w{4})(?: is not hidden\.)/);
            if (matches != null) {
              validationError = I18n.t(
                'controls.grid_view_column_editor.error.hide_column',
                { column_name: matches[1], child_view_id: matches[2] }
                );
            }
          }

          $columnEditor.find('.loadingSpinnerContainer').addClass('hidden');
          alert(
            (validationError != null ? validationError : I18n.t('controls.grid_view_column_editor.error.save'))
          );
          $columnEditor.find('button').removeAttr('disabled');
        }
      };

      $columnEditor.find('button').attr('disabled', true);
      $columnEditor.find('.loadingSpinnerContainer').removeClass('hidden');

      $.ajax(ajaxOptions);
    }

    function pollConvertColumnEndpointUntilComplete(url, onComplete) {
      var ajaxOptions = {
        url: url,
        type: 'GET',
        contentType: 'application/json',
        success: function(response) {
          if (response.hasOwnProperty('id') && response.hasOwnProperty('fieldName')) {
            window.location.reload(true);
          } else {

            $('.convert-column-data-type-status-text').text(response.details.message).show();

            setTimeout(function() { pollConvertColumnEndpointUntilComplete(url, onComplete); }, 5000);
          }
        },
        error: function() {
          alert(
            I18n.t('controls.grid_view_column_editor.error.convert_column_data_type')
          );
          $columnEditor.find('button').attr('disabled', false);
          $columnEditor.find('.loadingSpinnerContainer').addClass('hidden');
        }
      };

      $.ajax(ajaxOptions);
    }

    // EN-21574 - Why Can't I Unhide Hidden Columns?
    //
    // The Column Editor makes use of the Views API to update the view
    // with a single request. This works for everything except unhiding
    // columns, which is based on the absence of a flag, which is never
    // noticed because the Views API treats the absence of a thing as
    // meaning that the thing should not be updated.
    //
    // Because of this, we need to figure out--after the view has been
    // saved using the Views API--which columns had been intended to be
    // unhidden, and make individual requests to the Columns API one by
    // one in order to unhide each column that was intended to be
    // unhidden.
    function unhideUnhiddenColumns(beforeView, afterView, callback) {
      var unhideRequests = _.get(beforeView, 'columns', []).
        filter(function(column) {

          var editedColumnNotHidden = !_.includes(_.get(column, 'flags', []), 'hidden');
          var savedColumn = _.find(_.get(afterView, 'columns', []), {id: column.id });
          var savedColumnHidden = _.includes(_.get(savedColumn, 'flags', []), 'hidden');

          return editedColumnNotHidden && savedColumnHidden;
        }).
        map(function(columnToUpdate) {

          return new Promise(function(resolve, reject) {
            var ajaxOptions = {
              url: '/views/' + window.blist.dataset.id + '/columns/' + columnToUpdate.id + '.json',
              type: 'PUT',
              contentType: 'application/json',
              data: JSON.stringify({hidden: false}),
              dataType: 'json',
              success: resolve,
              error: function(jqXHR, textStatus, errorThrown) {
                var validationError = null;
                if (textStatus === 'error' && jqXHR.status === 400) {
                  var message = JSON.parse(jqXHR.responseText).message;
                  var matches = message.match(/(?:Validation failed: Column )(.+)(?: with id \d+ must be hidden if column on parent view )(\w{4}-\w{4})(?: is hidden\.)/);
                  if (matches != null) {
                    validationError = I18n.t('controls.grid_view_column_editor.error.unhide_column', { column_name: matches[1], parent_view_id: matches[2] });
                  }
                }

                $columnEditor.find('.loadingSpinnerContainer').addClass('hidden');
                alert(
                  (validationError != null ? validationError : I18n.t('controls.grid_view_column_editor.error.unhide_column_failed', { column_name: columnToUpdate.name }))
                );
                $columnEditor.find('.controls').find('button').attr('disabled', false);

                if (validationError == null) {
                  reject(jqXHR, textStatus, errorThrown);
                }
              }
            };

            $.ajax(ajaxOptions);
          });
        });

      Promise.
        all(unhideRequests).
        then(function() {

          if (_.isFunction(callback)) {
            callback();
          }
        }).
        catch(function() {

          // We still need to carry on, we just won't have succeeded :-(
          if (_.isFunction(callback)) {
            callback();
          }
        });
    }

    var $columnsContainer = $manager.find('.columns-container');
    var $columns = $manager.find('.column');
    var $tabLinks = $manager.find('.tab-link');
    var $hidden = $columns.find('.column-hidden');
    var $name = $columns.find('.column-name');
    var $displayFormat = $columns.
      find('.column-number-formatting-display-format');
    var $overridePrecision = $columns.
      find('.column-number-formatting-override-precision');
    var $overridePrecisionRange = $columns.
      find('.column-number-formatting-override-precision-range');
    var $showThousandsSeparators = $columns.
      find('.column-number-formatting-show-thousands-separators');
    var $overrideThousandsSeparator = $columns.
      find('.column-number-formatting-override-thousands-separator');
    var $overrideThousandsSeparatorValue = $columns.
      find('.column-number-formatting-override-thousands-separator-value');
    var $overrideDecimalSeparator = $columns.
      find('.column-number-formatting-override-decimal-separator');
    var $overrideDecimalSeparatorValue = $columns.
      find('.column-number-formatting-override-decimal-separator-value');
    var $prev = $manager.find('.prev');
    var $next = $manager.find('.next');
    var $save = $manager.find('.save');
    var $convertColumnDataType = $manager.find('.convert-column-data-type');
    var $delete = $manager.find('.delete');
    var $cancel = $manager.find('.overlay, .cancel');

    $tabLinks.on('click', function() {
      var tabId = this.getAttribute('data-tab-id');

      $tabLinks.removeClass('current');
      $columns.find('.tab-content').addClass('hidden');

      if (tabId === 'column-properties') {
        $columnsContainer.find('.tab-link[data-tab-id="column-properties"]').addClass('current');
        $columnsContainer.find('.tab-content[data-tab-id="column-properties"]').removeClass('hidden');
      } else if (tabId === 'column-formatting') {
        $columnsContainer.find('.tab-link[data-tab-id="column-formatting"]').addClass('current');
        $columnsContainer.find('.tab-content[data-tab-id="column-formatting"]').removeClass('hidden');
      } else if (tabId === 'column-delete') {
        $columnsContainer.find('.tab-link[data-tab-id="column-delete"]').addClass('current');
        $columnsContainer.find('.tab-content[data-tab-id="column-delete"]').removeClass('hidden');
      }
    });

    $hidden.on('change', function() {

      $(this).
        parents('.content').
        toggleClass('hidden', $(this).is(':checked'));
    });

    $name.on('input', function() {

      if ($(this).value() === null) {
        $save.attr('disabled', true);
      } else {
        $save.attr('disabled', false);
      }
    });

    $displayFormat.on('change', function() {

      $(this).
        parents('.field-group').
        find('.column-number-formatting-currency').
        parents('.field.optional').
        toggleClass('hidden', $(this).value() !== 'currency');
    });

    $overridePrecision.on('change', function(e) {
      var id = getColumnIdFromEvent(e);

      $(this).
        siblings('.column-number-formatting-override-precision-control').
        toggleClass('hidden', !$(this).is(':checked'));

      updateNumberFormattingPreview(id);
    });

    $overridePrecisionRange.on('input', function(e) {
      var id = getColumnIdFromEvent(e);

      updateNumberFormattingPreview(id);
    });

    $showThousandsSeparators.on('change', function(e) {
      var id = getColumnIdFromEvent(e);

      updateNumberFormattingPreview(id);
    });

    $overrideThousandsSeparator.on('change', function(e) {
      var id = getColumnIdFromEvent(e);

      $(this).
        siblings('.column-number-formatting-override-thousands-separator-control').
        toggleClass('hidden', !$(this).is(':checked'));

      updateNumberFormattingPreview(id);
    });

    $overrideThousandsSeparatorValue.on('input', function(e) {
      var id = getColumnIdFromEvent(e);

      updateNumberFormattingPreview(id);
    });

    $overrideDecimalSeparator.on('change', function(e) {
      var id = getColumnIdFromEvent(e);

      $(this).
        siblings('.column-number-formatting-override-decimal-separator-control').
        toggleClass('hidden', !$(this).is(':checked'));

      updateNumberFormattingPreview(id);
    });

    $overrideDecimalSeparatorValue.on('input', function(e) {
      var id = getColumnIdFromEvent(e);

      updateNumberFormattingPreview(id);
    });

    $prev.on('click', function() {
      updatePagination(-1, $columns);
    });

    $next.on('click', function() {
      updatePagination(+1, $columns);
    });

    $cancel.on('click', function() {

      if (confirm(I18n.t('controls.grid_view_column_editor.close_without_saving'))) {
        $columnEditor.remove();
      }
    });

    $save.on('click', function() {
      var columnFieldNamesFromForm = $columns.toArray().
        map(function(columnForm) {
          return $(columnForm).find('.column-field-name').value();
        });
      var viewHasDuplicateFieldNames = columnFieldNamesFromForm.length !== _.uniq(columnFieldNamesFromForm).length;

      if (viewHasDuplicateFieldNames) {

        alert(I18n.t('controls.grid_view_column_editor.cannot_save_duplicate_field_names'));

        return;
      }

      saveView(function() { window.location.reload(true); });
    });

    $convertColumnDataType.on('click', function(e) {

      // The NBE doesn't currently have the capability to convert column data types.
      if (window.blist.dataset.newBackend) {
        return;
      }

      if (confirm(I18n.t('controls.grid_view_column_editor.convert_column_data_type_confirm'))) {
        var columnId = $(e.target).attr('data-column-id');
        var $convertColumnDataTypeStatus = $('#convert-column-data-type-status-' + columnId);
        var convertColumn = function() {
          var dataType = $('#column-data-type-select-' + columnId).value();
          var ajaxOptions = {
            url: '/views/' + window.blist.dataset.id + '/columns/' + columnId + '.json?method=convert&type=' + dataType,
            type: 'POST',
            contentType: 'application/json',
            success: function(response) {
              var responseIsNewColumnJson = response.hasOwnProperty('tableColumnId');
              var onComplete = function() {
                window.location.reload(true);
              };

              if (responseIsNewColumnJson) {
                onComplete();
              } else {

                var url = (
                  '/views/' +
                  window.blist.dataset.id +
                  '/columns/' +
                  columnId +
                  '.json?method=convert&type=' +
                  dataType +
                  '&ticket=' +
                  response.ticket
                );

                pollConvertColumnEndpointUntilComplete(url, $convertColumnDataTypeStatus, onComplete);
              }
            },
            error: function() {
              alert(
                I18n.t('controls.grid_view_column_editor.error.delete')
              );
              $columnEditor.find('button').attr('disabled', false);
              $columnEditor.find('.loadingSpinnerContainer').addClass('hidden');
            }
          };

          $columnEditor.find('button').attr('disabled', true);
          $columnEditor.find('.loadingSpinnerContainer').removeClass('hidden');

          $.ajax(ajaxOptions);
        };

        $convertColumnDataTypeStatus.show();

        saveView(convertColumn);
      }
    });

    $delete.on('click', function(e) {

      if (confirm(I18n.t('controls.grid_view_column_editor.delete_column_confirm'))) {
        var columnId = $(e.target).attr('data-column-id');
        var ajaxOptions = {
          url: '/api/views/' + window.blist.dataset.id + '/columns/' + columnId + '.json',
          type: 'DELETE',
          dataType: 'text',
          contentType: 'text/plain',
          mimeType: 'text/plain',
          success: function() {
            window.location.reload(true);
          },
          error: function() {
            alert(
              I18n.t('controls.grid_view_column_editor.error.delete')
            );
            $columnEditor.find('button').attr('disabled', false);
            $columnEditor.find('.loadingSpinnerContainer').addClass('hidden');
          }
        };

        $columnEditor.find('.loadingSpinnerContainer').removeClass('hidden');
        $columnEditor.find('button').attr('disabled', true);

        $.ajax(ajaxOptions);
      }
    });
  }

  var columns = _.get(options, 'columns', []);
  var nonHiddenColumns = columns.filter(function(column) {
    return !_.includes(_.get(column, 'flags', []), 'hidden');
  });
  var columnsWithGroupingOrAggregatesConfigured = nonHiddenColumns.filter(function(column) {

    return (
      !_.isNull(_.get(column, 'format.drill_down', null)) &&
      !_.isNull(_.get(column, 'format.group_function', null)) &&
      !_.isNull(_.get(column, 'format.grouping_aggregate', null))
    );
  });
  var datasetHasGrouping = (
    !_.isEmpty(_.get(window, 'blist.dataset.query.groupBys', [])) ||
    !_.isEmpty(columnsWithGroupingOrAggregatesConfigured)
  );
  var datasetHasSoqlView = !!(_.get(window, 'blist.dataset.queryString'));
  var columnsToRenderInColumnEditor = (datasetHasGrouping) ?
    nonHiddenColumns :
    columns;
  var selectedColumnId = _.get(options, 'id', false);
  var selectedColumnIndex = columnsToRenderInColumnEditor.
    map(function(column) {
      return column.id;
    }).
    indexOf(selectedColumnId);
  var $columnEditor = renderColumnEditor(window.blist.dataset.id, columnsToRenderInColumnEditor, datasetHasSoqlView);
  var $firstColumn;
  var firstColumnName;

  attachColumnEditorEvents($columnEditor, columns);

  $('body').append($columnEditor);

  if (selectedColumnId && selectedColumnIndex >= 0) {
    var $columnToShow = $columnEditor.find('.column[data-column-id="' + selectedColumnId + '"]');
    $columnEditor.find('#column-name').text(
      generateColumnPositionString($columnToShow.find('.column-name').value(), selectedColumnIndex, columnsToRenderInColumnEditor.length)
    );
    $columnEditor.find('.column').addClass('hidden');
    $columnToShow.removeClass('hidden');
  } else {
    $firstColumn = $columnEditor.find('.column').first();
    firstColumnName = $firstColumn.find('input.column-name').value();
    $columnEditor.find('#column-name').text(
      generateColumnPositionString(firstColumnName, 0, columnsToRenderInColumnEditor.length)
    );
    $firstColumn.removeClass('hidden');
  }
};
