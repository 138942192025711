import React from 'react';
import ReactDOM from 'react-dom';
import get from 'lodash/get';

import { assign as windowLocationAssign } from 'common/window_location';
import { fetchJsonWithDefaultHeaders } from 'common/http';
import * as Rights from 'common/rights';
import { shouldCreateViewViaRevision } from 'common/views/helpers';


import QueryEditor from 'common/components/QueryEditor';

const render = () => {
  const queryEditorNodeId = '#grid-view-query-editor';
  let queryEditorContainer = document.querySelector(queryEditorNodeId);

  if (queryEditorContainer === null) {
    queryEditorContainer = document.createElement('div');
    document.querySelector('body').appendChild(queryEditorContainer);
  }

  //If the user has write access to this dataset query against the parent
  const assetUid = window.blist.dataset.canEdit()
    ? get(window, 'blist.dataset.modifyingViewUid')
    : get(window, 'blist.dataset.id');

  const columnBaseUid = get(window, 'blist.dataset.id');
  const view = get(window, 'blist.dataset');

  const query = get(window.blist.dataset.cleanCopy(), 'queryString');

  const createNewViewAndRevision = (newQuery, successCallback, failureCallback) => {
    window.blist.dataset.createViewAndRevision({queryString: newQuery}, successCallback, failureCallback);
  };

  const createNewView = (newQuery, successCallback, failureCallback) => {
    window.blist.dataset.createView((newView) => {
      newView.queryString = newQuery;

      const fetchOptions = {
        method: 'PUT',
        credentials: 'same-origin',
        body: JSON.stringify(newView)
      };

      fetchJsonWithDefaultHeaders(`/api/views/${newView.id}.json`, fetchOptions)
        .then(successCallback)
        .catch((error) => {
          throw new Error('Could not modify newly created view: ' + error);
        });
    }, failureCallback);
  };

  const unmountNode = () => {
    if (queryEditorContainer) {
      queryEditorContainer.remove();
    }
  };

  const onConfirm = (newQuery) => {
    const userRights = get(window, 'blist.currentUser.rights');

    // if the dataset has this flag, that means that it is based off of an incoming data-federated view
    // in this case, we do NOT want to create a new dataset! We are already looking at the 'child'.
    const isUnfederatedViewBasedOnInternalToPublicFederatedDataset = (
      window.blist.dataset.flags || []
    ).includes('unfederatedViewBasedOnInternalToPublicFederatedDataset');

    // we need a new 4x4 if we don't have write to this view but can create new views
    if (
      !window.blist.dataset.canEdit() &&
      !isUnfederatedViewBasedOnInternalToPublicFederatedDataset &&
      userRights.includes(Rights.CREATE_DATASETS)
    ) {
      if (shouldCreateViewViaRevision()) {
        createNewViewAndRevision(
          newQuery,
          (createResult) => {
            windowLocationAssign(`/d/${createResult.fourfour}/revisions/${createResult.revision_seq}/soql`);
          },
          (error) => {
            throw new Error('Unable to create new view and revision: ' + error);
          }
        );
      } else {
        createNewView(
          newQuery,
          (createResult) => {
            windowLocationAssign(`/d/${createResult.id}/data`);
          },
          (error) => {
            throw new Error('Unable to create new view: ' + error);
          }
        );
      }

    } else {
      const encodedQuery = encodeURIComponent(newQuery);
      const fullUpdate = false;
      const minorUpdate = true;
      // EN-31278 - Grid view doesn't update after SoQL Query Editor update
      //
      // masterUpdate, below, was previously true. This parameter being true
      // causes the dataset model (/util/dataset/dataset.js) to overwrite the
      // 'saved' version of the dataset (the one with which the page was loaded)
      // with the contents of the update. This is basically never what we want,
      // but it might be a behavior that something else of which I am unaware
      // relies on, so I'm not sure I want to remove that behavior just yet.
      //
      // The downstream effect of passing masterUpdate = true to the dataset
      // model's update method was that when the grid view went to query rows
      // it would believe that the only-just-updated queryString that the user
      // typed into the SoQL Query Editor was actually on the saved view and
      // dispatch a select * query instead of using the query string the user
      // just typed in. Saving the view would cause the query string the user
      // just typed in to actually be on the 'saved' view and a select * would
      // subsequently show the correct results.
      //
      // This change should cause the grid view to dispatch the correct query
      // (the one the user just typed in to the SoQL Query Editor) before the
      // view is saved, which is what one would expect given the 'Update Query'
      // label on the button that they would have clicked to get to this point.
      const masterUpdate = false;

      fetchJsonWithDefaultHeaders(
        `/api/views/${assetUid}.json?method=getColumnsForViewWithQuery&columnBaseUid=${columnBaseUid}&query=${encodedQuery}`,
        { credentials: 'same-origin' }
      ).then((columns) => {
        window.blist.dataset.update(
          { queryString: newQuery, columns },
          fullUpdate,
          minorUpdate,
          masterUpdate
        );
        unmountNode();
      });
    }
  };

  const onCancel = () => {
    unmountNode();
  };

  ReactDOM.render(
    <QueryEditor
      assetUid={assetUid}
      currentView={view}
      query={query}
      onConfirm={onConfirm}
      onCancel={onCancel}
      confirmButtonText="Update Query"
    />,
    queryEditorContainer
  );
};

export default render;
